import React from "react";
import { Link } from "react-router-dom";
import Zoom from "react-reveal/Zoom";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Home = () => {
  return (
    <>
      <div className="Home">
        <h1 className="welcome-home1">Yuriy</h1>
        <h1 className="welcome-home2">Horbatenko</h1>

        <div className="Home-links">
          <Zoom
            bottom
            cascade>
            <ReactTooltip
              border
              className="tooltip"
            />
            <ul>
              <li
                className="github"
                data-tip="GitHub">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/yuriyhorbatenko">
                  <i
                    className="fab fa-github"
                    aria-hidden="true"></i>
                </a>
              </li>

              <li
                className="linkedin"
                data-tip="LinkedIn">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/yuriy-horbatenko-2011911a7/">
                  <i
                    className="fab fa-linkedin"
                    aria-hidden="true"></i>
                </a>
              </li>

              <li
                className="google"
                data-tip="Resume">
                <Link
                  className="maillink"
                  to="/resume"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i
                    className="far fab fa-file"
                    aria-hidden="true"></i>
                </Link>
              </li>
            </ul>
          </Zoom>
        </div>
      </div>
    </>
  );
};

export default Home;
