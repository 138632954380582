export const projects = [
  {
    id: 1,
    src: require("../assets/images/project3.jpg"),
    name1: "Private Practice",
    name2: "Group Project - 3",
    desc: "Mental Health Private Practice App designed for patients which can easily schedule an appointment with specialist who help them",
    tech: "HTML, CSS, JS, Jquery, Node, Mongo Atlas, Express, React Redux, Postman",
    link1: "https://github.com/yuriyhorbatenko/Project_-3",
    link2: "https://project-3-evan-jay-mike-yuriy.herokuapp.com/",
  },
  {
    id: 2,
    src: require("../assets/images/google.jpg"),
    name1: "Google Books",
    name2: "MERN API Search",
    desc: "React-based Google Books Search app which display books based on user searches",
    tech: "HTML, CSS, JS, Jquery, Node, Express, Mongo, React, Google API",
    link1: "https://github.com/yuriyhorbatenko/Google-Books-Search",
    link2: "https://google-books-search-yuriy.herokuapp.com/",
  },
  {
    id: 3,
    src: require("../assets/images/project2.jpg"),
    name1: "Cheerful Hippo",
    name2: "Group Project - 2",
    desc: "Donut Shop App where customers can view and order specialty donuts and drinks",
    tech: "HTML, CSS, JS, Jquery, Node, Handlebars, MySQL",
    link1: "https://github.com/yuriyhorbatenko/Project_-2",
    link2: "https://project-2-ken-dani-yuriy.herokuapp.com/",
  },
  {
    id: 4,
    src: require("../assets/images/friends.jpg"),
    name1: "Friend Finder",
    name2: "Node & Express Servers",
    desc: "This app will then display the name and picture of the user with the best overall match",
    tech: "HTML, CSS, JS, Jquery, Node, Express",
    link1: "https://github.com/yuriyhorbatenko/Friend-Finder",
    link2: "https://thawing-hamlet-45143.herokuapp.com/",
  },
  {
    id: 5,
    src: require("../assets/images/project1.jpg"),
    name1: "Top Flights",
    name2: "Group Project - 1",
    desc: "Travel App with weather API and Firebase with ability for user to book a flight",
    tech: "HTML, CSS, JS, Jquery, API, Firebase",
    link1: "https://github.com/yuriyhorbatenko/Project_-1",
    link2: "https://yuriyhorbatenko.github.io/Top-Flights/",
  },
  {
    id: 6,
    src: require("../assets/images/trivia.jpg"),
    name1: "Music 90's",
    name2: "Trivia Game",
    desc: "Music app has a library of questions and answers themed around Music 90's. For this app you will need to turn your Sound On",
    tech: "HTML, CSS, JS, Jquery",
    link1: "https://github.com/yuriyhorbatenko/TriviaGame",
    link2: "https://yuriyhorbatenko.github.io/Music-90-s/",
  },
  {
    id: 7,
    src: require("../assets/images/clicky.jpg"),
    name1: "Mortal Kombat",
    name2: "React Clicky Game",
    desc: "React-based memory game. The object of this game is to click on only one character once",
    tech: "HTML, CSS, JS, Jquery, Node, React",
    link1: "https://github.com/yuriyhorbatenko/Clicky-Game",
    link2: "https://clicky-game-yuriy.herokuapp.com/",
  },
  {
    id: 8,
    src: require("../assets/images/burger.jpg"),
    name1: "Burger Diner",
    name2: "Handlebars App",
    desc: "A Node, Express, Handlebars, and MySQL burger app that lets users input the names of burgers they'd like to order and then checkout",
    tech: "HTML, CSS, JS, Jquery, Node, Handlebars, MySQL",
    link1: "https://github.com/yuriyhorbatenko/Burger",
    link2: "https://guarded-inlet-83422.herokuapp.com/",
  },
  {
    id: 9,
    src: require("../assets/images/game.jpg"),
    name1: "Mortal Kombat",
    name2: "RPG Game",
    desc: "A single-player game where the user chooses their MK Fighter at the start of the game and battles other MK Fighters",
    tech: "HTML, CSS, JS, Jquery",
    link1: "https://github.com/yuriyhorbatenko/unit-4-game",
    link2: "https://yuriyhorbatenko.github.io/Mortal-Kombat-RPG-Game/",
  },
  {
    id: 10,
    src: require("../assets/images/Train.jpg"),
    name1: "Train Scheduler",
    name2: "Firebase App",
    desc: "Providing up-to-date information about trains, their arrival times and how many minutes remain until they arrive",
    tech: "HTML, CSS, JS, Jquery, Firebase",
    link1: "https://github.com/yuriyhorbatenko/Train-Scheduler",
    link2: "https://yuriyhorbatenko.github.io/Train-Scheduler/",
  },
  {
    id: 11,
    src: require("../assets/images/giftastic.jpg"),
    name1: "Giftastic",
    name2: "Giphy API",
    desc: "This website uses the GIPHY API to make a dynamic web page that populates with gifs of your choice",
    tech: "HTML, CSS, JS, Jquery, API",
    link1: "https://github.com/yuriyhorbatenko/GifTastic",
    link2: "https://yuriyhorbatenko.github.io/GifTastic/",
  },
  {
    id: 12,
    src: require("../assets/images/word.jpg"),
    name1: "The Matrix",
    name2: "Word Guess Game",
    desc: "The Matrix characters as a word guessing game. There are 7 characters in total. The words are their movie names",
    tech: "HTML, CSS, JS",
    link1: "https://github.com/yuriyhorbatenko/Word-Guess-Game",
    link2: "https://yuriyhorbatenko.github.io/Word-Guess-Game/",
  },
];
