import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { usePdf } from "react-pdf-js";
import Zoom from "react-reveal/Zoom";

const Resume = () => {
  const canvasEl = useRef(null);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const resume = require("../assets/resume/Yuriy_Horbatenko_Resume.pdf");

  const renderPagination = (page, pages) => {
    if (!pages) {
      return null;
    }

    let previousButton = (
      <li
        className="previous"
        onClick={() => setPage(page - 1)}>
        <a href="/#">
          <i className="fas fa-arrow-left"></i> Previous
        </a>
      </li>
    );

    if (page === 1) {
      previousButton = (
        <li className="previous disabled">
          <a href="/#">
            <i className="fas fa-arrow-left"></i> Previous
          </a>
        </li>
      );
    }

    let nextButton = (
      <li
        className="next"
        onClick={() => setPage(page + 1)}>
        <a href="/#">
          Next <i className="fas fa-arrow-right"></i>
        </a>
      </li>
    );

    if (page === pages) {
      nextButton = (
        <li className="next disabled">
          <a href="/#">
            Next <i className="fas fa-arrow-right"></i>
          </a>
        </li>
      );
    }

    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  };

  const [loading, numPages] = usePdf({
    file: resume,
    page,
    canvasEl,
  });

  useEffect(() => {
    setPages(numPages);
  }, [numPages]);

  return (
    <Zoom cascade>
      <div className="pdf-content">
        {loading && <span>Loading...</span>}

        <canvas
          className="pdf-page"
          ref={canvasEl}
        />

        <div className="buttons-border">
          <div className="page-controls">{renderPagination(page, pages)}</div>
          <div className="download-buttons">
            <Link
              to={require("../assets/resume/Yuriy_Horbatenko_Resume.docx")}
              target="_blank"
              download>
              <Button
                variant="primary"
                className="submit-button download-doc">
                Download in .docx<span></span>
                <span></span>
                <span></span>
                <span></span>
              </Button>
            </Link>
            <Link
              to={require("../assets/resume/Yuriy_Horbatenko_Resume.pdf")}
              target="_blank"
              download>
              <Button
                variant="primary"
                className="submit-button download-pdf">
                Download in .pdf<span></span>
                <span></span>
                <span></span>
                <span></span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Zoom>
  );
};

export default Resume;
