import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./pages/Main.js";
import Resume from "./pages/Resume.js";
import NoMatch from "./pages/NoMatch.js";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          component={Main}
        />
        <Route
          exact
          path="/resume"
          component={Resume}
        />
        <Route component={NoMatch} />
      </Switch>
    </Router>
  );
};

export default Routes;
