import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import * as emailjs from "emailjs-com";

const ContactForm = () => {
  const [value, setValue] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = value;
    let templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };
    console.log(templateParams);
    emailjs.send("service_3lip7st", "template_fs9gwk4", templateParams, "TgsmfJd2mxm5uHm7s").then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        console.log("FAILED...", error);
      },
    );
    // resetForm();
  };

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  function resetForm() {
    setValue({
      name: "",
      email: "",
      message: "",
    });
  }

  return (
    <>
      <div className="contact-form">
        <div className="contact-text">
          <h1>Send Me Email</h1>
        </div>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label className="text-muted">E-mail</Label>
            <Input
              type="email"
              name="email"
              value={value.email}
              className="text-primary"
              onChange={handleChange}
              placeholder="Enter email"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-muted">Name</Label>
            <Input
              type="text"
              name="name"
              value={value.name}
              className="text-primary"
              onChange={handleChange}
              placeholder="Enter name"
              required
            />
          </FormGroup>
          <FormGroup>
            <Label className="text-muted">Message</Label>
            <Input
              type="textarea"
              name="message"
              id="exampleText"
              rows="10"
              cols="30"
              value={value.message}
              onChange={handleChange}
              placeholder="Enter message"
              required
            />
          </FormGroup>
          <Button
            variant="primary"
            type="submit"
            className="submit-button">
            Submit<span></span>
            <span></span>
            <span></span>
            <span></span>
          </Button>
        </Form>
      </div>

      <div className="Contact">
        <Zoom
          top
          cascade>
          <ReactTooltip
            border
            className="tooltip"
          />
          <ul>
            <li data-tip="GitHub">
              <a
                target="_blank"
                rel="noopener noreferrer"
                id="left-start"
                href="https://github.com/yuriyhorbatenko">
                <i className="fab fa-github icon"></i>
              </a>
            </li>
            <li data-tip="LinkedIn">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/yuriy-horbatenko-2011911a7/">
                <i className="fab fa-linkedin-in icon"></i>
              </a>
            </li>
            <li data-tip="Resume">
              <Link
                className="maillink"
                to="/resume"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fab far fa-file icon"></i>
              </Link>
            </li>
          </ul>

          <div className="copyright">
            <p>Copyright&copy;</p>
          </div>
          <div className="year2020">
            <p>2020</p>
          </div>
        </Zoom>
      </div>
    </>
  );
};

export default ContactForm;
