import React from "react";
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';


const NoMatch = () => {
  return (

    <>
      <div id="notfound">
        <div className="notfound">
          <Fade top>
            <div className="notfound-404">
              <h1>Oops!</h1>
            </div>
          </Fade>
          <Fade bottom>
            <h2>404 - Page not found</h2>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
            <Link to="/">Go To Homepage</Link>
          </Fade>
        </div>
      </div>
    </>

  );
}

export default NoMatch;
