import { Link } from "react-scroll";
import React, { useRef } from "react";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import Portfolio from "../components/Portfolio.js";
import Background from "../assets/images/6.jpg";
import Scrolls from "../components/Scrolls.js";
import Contact from "../components/Contact";
import About from "../components/About.js";
import Home from "../components/Home.js";
import Fade from "react-reveal/Fade";

const Main = () => {
  const speed = 1;
  const pages = 2.5;
  const backSpeed = 0.1;
  const parallax = useRef(null);

  return (
    <>
      <div className="header">
        <Fade
          top
          cascade>
          <ul>
            <li>
              <Link
                activeClass="active"
                className="navbar-link"
                to="Home"
                spy={true}
                smooth={true}
                duration={600}
                key="0"
                onClick={() => parallax.current.scrollTo(0)}>
                Home
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="navbar-link"
                to="About"
                spy={true}
                smooth={true}
                duration={600}
                key="1"
                onClick={() => parallax.current.scrollTo(0.45)}>
                About
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="navbar-link"
                to="Portfolio"
                spy={true}
                smooth={true}
                duration={600}
                key="2"
                onClick={() => parallax.current.scrollTo(0.975)}>
                Portfolio
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                className="navbar-link"
                to="Contact"
                spy={true}
                smooth={true}
                duration={600}
                key="3"
                onClick={() => parallax.current.scrollTo(1.48)}>
                Contact
              </Link>
            </li>
          </ul>
        </Fade>
      </div>

      <Scrolls />

      <div className="Sections">
        <Parallax
          ref={parallax}
          pages={pages}>
          <ParallaxLayer
            offset={0}
            speed={backSpeed}
            factor={5}
            style={{
              backgroundImage: `url(${Background})`,
            }}
          />

          <ParallaxLayer
            offset={0}
            speed={speed}
            factor={1}>
            <section id="Home">
              <Home />
            </section>
          </ParallaxLayer>

          <ParallaxLayer
            offset={0.9}
            speed={speed}>
            <section id="About">
              <About />
            </section>
          </ParallaxLayer>

          <ParallaxLayer
            offset={1}
            speed={speed}>
            <section id="Portfolio">
              <Portfolio />
            </section>
          </ParallaxLayer>

          <ParallaxLayer
            offset={1.9}
            speed={speed}>
            <section id="Contact">
              <Contact />
            </section>
          </ParallaxLayer>
        </Parallax>
      </div>
    </>
  );
};

export default Main;
