import React from "react";
import { projects } from "./projects-list.js";

const Portfolio = () => {
  return (
    <>
      <div className="Portfolio">
        <div className="grid">
          {projects.map((list) => (
            <figure
              key={list.id}
              className="effect-julia">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={list.link1}>
                <img
                  alt=""
                  src={list.src}
                />
                <figcaption>
                  <h2>
                    {list.name1}
                    <br></br>
                    <span>{list.name2}</span>
                  </h2>
                  <h3>{list.desc}</h3>
                  <p>Languages & Technologies Used:</p>
                  <p>{list.tech}</p>
                  <br></br>
                  <p id="outside-links">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={list.link1}>
                      <i className="fab fa-github"></i>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={list.link2}>
                      <i className="far fa-play-circle"></i>
                    </a>
                  </p>
                </figcaption>
              </a>
            </figure>
          ))}
        </div>
      </div>
    </>
  );
};

export default Portfolio;
