export const skills = [
  { class: "html", link: "https://html.spec.whatwg.org/multipage/", src: require("../assets/images/html.png"), name: "HTML 5" },
  { class: "css", link: "https://www.w3.org/Style/CSS/Overview.en.html", src: require("../assets/images/css.png"), name: "CSS 3" },
  { class: "javascript", link: "https://www.javascript.com/", src: require("../assets/images/javascript.png"), name: "JavaScript" },
  { class: "jquery", link: "https://jquery.com/", src: require("../assets/images/jquery.png"), name: "jQuery" },
  { class: "bootstrap", link: "https://getbootstrap.com/", src: require("../assets/images/bootstrap.png"), name: "Bootstrap" },
  { class: "Firebase", link: "https://firebase.google.com/", src: require("../assets/images/firebase.png"), name: "Firebase" },
  { class: "node", link: "https://nodejs.org/en/", src: require("../assets/images/node.png"), name: "Node.js" },
  { class: "mysql", link: "https://www.mysql.com/", src: require("../assets/images/mysql.png"), name: "MySQL" },
  { class: "mongo", link: "https://www.mongodb.com/", src: require("../assets/images/mongo.png"), name: "mongoDB" },
  { class: "handlebars", link: "https://handlebarsjs.com/", src: require("../assets/images/handlebars.png"), name: "Handlebars" },
  { class: "react", link: "https://reactjs.org/", src: require("../assets/images/react.png"), name: "React" },
  { class: "angular", link: "https://angularjs.org/", src: require("../assets/images/angular.png"), name: "AngularJS" },
];
