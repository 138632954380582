import React from "react";
import Fade from 'react-reveal/Fade';


const Scrolls = () => {
  return (
    <>
      <div className="scrolls">
        <Fade right cascade>
          <div className="up"><span>↑</span>
            <div className="center"><span>mouse <br></br>scrolling</span></div>
          </div>
          <div className="down"><span>↓</span></div>
        </Fade>
      </div>
    </>
  );
};

export default Scrolls;