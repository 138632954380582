import React from "react";
import { hydrate, render } from "react-dom";
import Routes from "./Routes";
import "./assets/styling/Index.scss";

import reportWebVitals from "./reportWebVitals";
import registerServiceWorker from "./registerServiceWorker.js";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Routes />, rootElement);
} else {
  render(<Routes />, rootElement);
}

reportWebVitals();
registerServiceWorker();
