import React from "react";
import { skills } from "./skills-list.js";

function About() {
  return (
    <>
      <div className="about">
        <div className="about-text-header">About Me</div>
        <div className="about-header">
          {/* Picture */}
          <div className="about-photo">
            <img
              alt=""
              className="about-img"
              src={require("../assets/images/about-img2.jpg")}
            />
          </div>

          {/* Text */}
          <div className="about-text-content">
            Hi, my name is Yuriy Horbatenko. I graduated from the University of Pennsylvania in 2020 and I'm proficient Full-Stack Web
            Developer. Demonstrated interpersonal skills with the ability to work adeptly under pressure both independently and
            collaboratively in a diverse setting to ensure like-minded outcomes. Personal attention to details coupled with a strong design
            background that allows effective development of user-friendly applications front to back end.
          </div>
        </div>

        <div className="about-text-bottom">Skills</div>
        {/* Icons list */}
        <div className="about-bottom">
          <div className="about-skills">
            {skills.map((list) => (
              <div
                key={list.class}
                className={`icon ${list.class}`}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={list.link}>
                  <img
                    alt=""
                    src={list.src}
                    width="50"
                    height="50"
                  />
                  <span>{list.name}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
